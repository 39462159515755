@keyframes loading {
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

.message-banner-visible .content, .main .content {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto; }

.circles-panel:after, .help-block li:after {
  content: '';
  clear: both;
  display: block;
  width: 100%;
  overflow: hidden; }

.hero .need-an-expert-block .staff-cards li img {
  display: block;
  width: 100%; }

.hidden, .message-banner-hidden .block, .main h1, .hero .hero-logos {
  display: none; }

.hero .hero-home {
  display: block;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  overflow: hidden; }

@keyframes hippyshake {
  0% {
    transform: translateX(2px); }
  20% {
    transform: translateX(0); }
  40% {
    transform: translateX(-2px); }
  60% {
    transform: translateX(0); }
  80% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0); } }

.message-banner-visible a {
  border: none;
  border-radius: 6px;
  background: #5F2A4F;
  color: #fff;
  text-align: center; }

.message-banner-visible {
  position: relative;
  z-index: 3; }
  .message-banner-visible .block {
    background-color: #00A1AA;
    color: #fff;
    padding: 1rem;
    margin: 0;
    text-align: center; }
  .message-banner-visible a {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 1rem; }
    .message-banner-visible a:hover {
      background-color: #fff;
      color: #5F2A4F; }
  .message-banner-visible p {
    margin: 0; }

.main {
  max-width: none;
  padding: 0 1.5rem; }
  @media only screen and (min-width: 81.25em) {
    .main {
      padding: 0 1.5rem; } }

.hero .hero-home {
  width: 100%; }

.hero .hero-inner {
  background: none; }
  @media only screen and (min-width: 60em) {
    .hero .hero-inner {
      padding: 3rem 1.5rem 1.5rem; } }
  @media only screen and (min-width: 100em) {
    .hero .hero-inner {
      padding: 3rem 0 1.5rem; } }

.hero .company-logo {
  margin-bottom: 1.5rem; }
  @media only screen and (min-width: 60em) {
    .hero .company-logo {
      margin: 0 0 3rem; } }

.hero .calls-to-action {
  transform: none;
  position: relative;
  clear: both;
  text-align: center;
  margin: 0 0 3rem; }
  .hero .calls-to-action li {
    display: inline-block;
    float: none; }
    .hero .calls-to-action li .icon-link,
    .hero .calls-to-action li .icon-content {
      width: 250px;
      font-size: 1.4rem;
      height: 60px; }
  @media only screen and (min-width: 80em) {
    .hero .calls-to-action {
      position: absolute;
      top: 2rem;
      left: 360px;
      margin: 0;
      width: 250px; }
      .hero .calls-to-action li {
        display: block;
        margin: 0 0 0.6rem 0; }
        .hero .calls-to-action li:not(:first-child) {
          margin: 0; } }

.hero .hero-logos a {
  display: inline-block; }
  .hero .hero-logos a:last-of-type {
    margin-left: 0.5rem; }

.hero .hero-logos .block {
  margin-bottom: 0; }

.hero .hero-logos img {
  max-height: 100px; }

@media only screen and (min-width: 60em) {
  .hero .hero-logos {
    display: block;
    height: auto;
    float: right; } }

@media only screen and (min-width: 80em) {
  .hero .hero-logos {
    position: relative;
    top: -10px; }
    .hero .hero-logos img {
      max-height: 121px; } }

@media only screen and (min-width: 100em) {
  .hero .hero-logos {
    right: 0; } }

.hero .hero-text-block {
  clear: both;
  background: #95A4BD;
  margin: 0 0 1.5rem; }
  @media only screen and (min-width: 60em) {
    .hero .hero-text-block {
      float: left;
      width: calc((100% - 1.5rem) / 2);
      margin: 0 0 0 1.5rem; }
      .hero .hero-text-block:nth-child(2n+1) {
        margin-left: 0; } }
  .hero .hero-text-block .content {
    padding: 2.25rem; }
    @media only screen and (min-width: 60em) {
      .hero .hero-text-block .content {
        padding: 3.75rem; } }
    .hero .hero-text-block .content h1 {
      font-weight: 400;
      line-height: 1.7; }
      .hero .hero-text-block .content h1 a {
        color: #5F2A4F; }
        .hero .hero-text-block .content h1 a:hover {
          color: #111; }
    .hero .hero-text-block .content p,
    .hero .hero-text-block .content h1 {
      font-size: 1.5rem;
      color: #fff;
      margin-bottom: 4rem; }
    .hero .hero-text-block .content .button {
      padding: 0.8rem 3.75rem; }
      @media only screen and (min-width: 60em) {
        .hero .hero-text-block .content .button {
          font-size: 1.6rem; } }

.hero .need-an-expert-block {
  margin: 0;
  background: #fff;
  width: 100%;
  position: relative; }
  @media only screen and (min-width: 60em) {
    .hero .need-an-expert-block {
      float: left;
      width: calc((100% - 1.5rem) / 2);
      margin: 0 0 0 1.5rem;
      min-height: 620px; }
      .hero .need-an-expert-block:nth-child(2n+1) {
        margin-left: 0; } }
  .hero .need-an-expert-block .title {
    min-height: 60px; }
  .hero .need-an-expert-block .staff-cards {
    padding: .5rem .5rem 0; }
    @media only screen and (min-width: 60em) {
      .hero .need-an-expert-block .staff-cards {
        position: absolute;
        bottom: 0; } }
    .hero .need-an-expert-block .staff-cards li {
      float: left; }
      .hero .need-an-expert-block .staff-cards li:nth-of-type(n) {
        clear: none;
        width: calc((99.9999% - 1rem) / 3);
        margin: 0 0 0.5rem 0.5rem; }
      .hero .need-an-expert-block .staff-cards li:nth-of-type(3n+1) {
        clear: both;
        margin-left: 0; }
      .hero .need-an-expert-block .staff-cards li a {
        display: block;
        position: relative; }
        .hero .need-an-expert-block .staff-cards li a:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: #00A1AA;
          transition: all 0.3s ease-out;
          opacity: 0; }
        .hero .need-an-expert-block .staff-cards li a:hover:after {
          content: '';
          opacity: .4; }
        .hero .need-an-expert-block .staff-cards li a span {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(0, 161, 170, 0.65);
          padding: 0.375rem 0.5rem;
          color: #fff;
          font-size: 0.9rem; }
  .hero .need-an-expert-block .title {
    font-size: 2.8rem;
    text-align: center;
    padding-top: 3rem; }

.circles-panel {
  background-color: #e4e6ea;
  margin-bottom: 0;
  padding: 1.5rem; }
  @media only screen and (min-width: 80em) {
    .circles-panel {
      margin-left: -1.5rem;
      margin-right: -1.5rem; } }
  .circles-panel .circles li {
    font-size: 90%; }
    @media only screen and (min-width: 32.5em) {
      .circles-panel .circles li {
        width: 75%;
        margin: auto; } }
    @media only screen and (min-width: 40em) {
      .circles-panel .circles li {
        width: 50%; } }
    @media only screen and (min-width: 60em) {
      .circles-panel .circles li {
        float: left; }
        .circles-panel .circles li:nth-of-type(n) {
          clear: none;
          width: calc((99.9999% - 6rem) / 3);
          margin: 0 0 0 3rem; }
        .circles-panel .circles li:nth-of-type(3n+1) {
          clear: both;
          margin-left: 0; } }
    .circles-panel .circles li:not(:last-child) {
      margin-bottom: 1.5rem; }
    .circles-panel .circles li .title {
      font-size: 1.4rem;
      display: block; }
    .circles-panel .circles li img {
      display: block;
      margin: 0 auto; }
    .circles-panel .circles li a {
      position: relative;
      border-radius: 50%;
      text-align: center;
      background: #fff;
      display: block;
      background: #5F2A4F;
      color: #fff; }
      .circles-panel .circles li a:before {
        content: '';
        display: block;
        width: 100%;
        height: 0;
        padding: 50% 0; }
      .circles-panel .circles li a:hover {
        background: #fff;
        color: #5F2A4F; }
      .circles-panel .circles li a span:first-child {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .circles-panel .circles li a span:nth-child(3) {
        color: #00A1AA; }

.somek-testimonial-block {
  margin-bottom: 3rem;
  background: url("/images/services/overview-banner.jpg") no-repeat;
  background-size: cover; }
  .somek-testimonial-block .content blockquote {
    border-left: 0; }

.pre-footer {
  background: #F2F3F5; }
  .pre-footer .call-to-action-pf {
    padding: 4.5rem 0; }

.help-block {
  margin-bottom: 4rem; }
  @media only screen and (min-width: 80em) {
    .help-block {
      width: 45%;
      float: left;
      padding-top: 1.5rem;
      margin: 0; } }
  .help-block .title {
    border-bottom: 1px solid #bbb;
    padding-bottom: 0.75rem;
    font-size: 2.2rem; }
  .help-block li {
    position: relative; }
    .help-block li:not(:last-child) {
      margin-bottom: 3rem; }
    @media only screen and (min-width: 60em) {
      .help-block li:nth-child(even) {
        padding-left: 3rem; } }
    .help-block li:after, .help-block li:before {
      content: '';
      display: block; }
    .help-block li:before {
      float: left;
      background: url("/images/icons/pen-icon.png") no-repeat;
      width: 98px;
      height: 98px; }
    .help-block li:after {
      position: relative;
      top: 1.5rem;
      background: #cfcfcf;
      height: 1px;
      width: 30%;
      margin: 0 auto; }
    .help-block li a {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding-left: 1rem;
      font-size: 1.2rem;
      font-weight: 300;
      color: #52616D;
      transition: all .3s ease; }
      .help-block li a:hover {
        color: #5F2A4F; }
      .help-block li a:after, .help-block li a:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
      .help-block li a:before {
        background: #5F2A4F;
        width: 16px;
        height: 16px;
        border-radius: 2px;
        right: 0; }
      .help-block li a:after {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #fff;
        right: 0.3rem; }
      @media only screen and (min-width: 32.5em) {
        .help-block li a {
          padding-right: 1.5rem; } }
      @media only screen and (min-width: 60em) {
        .help-block li a {
          font-size: 1.5rem;
          padding-right: 4.5rem; } }

@media only screen and (min-width: 80em) {
  .pre-footer-blog,
  .pre-footer-twitter-container {
    float: left;
    width: 45%;
    margin-left: 10%; } }

.pre-footer-blog .item,
.pre-footer-twitter-container .item {
  float: none;
  background: #95A4BD; }
  .pre-footer-blog .item:nth-child(n),
  .pre-footer-twitter-container .item:nth-child(n) {
    margin: 0;
    width: 100%; }
  .pre-footer-blog .item:first-child,
  .pre-footer-twitter-container .item:first-child {
    margin-bottom: 1.5rem; }

.pre-footer-blog .item {
  float: none; }
  .pre-footer-blog .item:first-child {
    margin-bottom: 1.5rem; }
