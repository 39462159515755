
//==========  Import modules  ==========//

@import '../modules/global/config/_index';
@import '../modules/global/utils/_index';
@import '../modules/global/layouts/_index';
@import '../modules/local/typography/_extends';

// home message banner

// .home-promo-banner {
// 	background-image: url(/images/home/promo-banners/banner_mobile_portrait.jpg);
// 	background-repeat: no-repeat;
// 	background-position: center center;
// 	background-size: cover;
// 	padding-bottom: 50%;

// 	@media only screen
// 		and (min-width: 25px)
// 		and (orientation: landscape) {
// 			background-image: url(/images/home/promo-banners/banner_mobile_landscape.jpg);
// 			padding-bottom: 20%;
// 	}

// 	@media #{$mobileXL} {
// 		background-image: url(/images/home/promo-banners/banner_tablet_portrait.jpg);
// 		padding-bottom: 25%;
// 	}

// 	@media only screen
// 		and (min-width: 50em)
// 		// and (max-width: 80em)
// 		and (orientation: landscape) {
// 			background-image: url(/images/home/promo-banners/banner_tablet_landscape.jpg);
// 			padding-bottom: 15%;
// 	}

// 	@media #{$desktop} {
// 		background-image: url(/images/home/promo-banners/banner_desktop.jpg);
// 		padding-bottom: 10%;
// 	}
// }

.message-banner-visible {
	position: relative;
	z-index: z(front) + 2;

	.block {
		background-color: $color-secondary;
		color: $color-white;
		padding: $site-padding / 1.5;
		margin: 0;
		text-align: center;
	}

	.content {
		@extend %full-width;
	}

	a {
		@extend %button;
		display: inline-block;
		padding: ($site-padding / 3) ($site-padding / 1.5);
		font-size: $h6 - .1;

		&:hover {
			background-color: $color-white;
			color: $color-primary;
		}
	}

	p {
		margin: 0;
	}
}

.message-banner-hidden {

	.block {
		@extend .hidden;
	}
}

// Main
.main {
	max-width: none;
	padding: 0 $site-padding;

		@media #{$maxWidth} {
			padding: 0 $site-padding;
		}

	.content {
		@extend %full-width;
	}

	h1 {
		@extend .hidden;
	}
}

// Hero
.hero {

	.hero-home {
		@extend %elastic;
		width: 100%;
	}

	.hero-inner {
		background: none;

			@media #{$tabletXL} {
				padding: ($site-padding * 2) $site-padding $site-padding;
			}

			@media #{$desktop} {
				padding: ($site-padding * 2) 0 $site-padding;
			}
	}

	// Logo
	.company-logo {
		margin-bottom: $site-padding;

		@media #{$tabletXL} {
			margin: 0 0 ($site-padding * 2);
		}
	}

	// CTAs
	.calls-to-action {
		// left: calc(45% + .5rem);
		// top: 2rem;
		transform: none;
		position: relative;
		clear: both;
		text-align: center;
		margin: 0 0 3rem;;


		li {
			display: inline-block;
			float: none;

			.icon-link,
			.icon-content {
				width: 250px;
				font-size: $font-size-body + .3;
				height: $nav-height - 20px;
			}
		}

		@media #{$laptop} {
			// left: calc(50% + .5rem);
			position: absolute;
			top: 2rem;
			left: 360px;
			margin: 0;
			width: 250px;

			li {
				display: block;
				margin: 0 0 ($block-margin / 5) 0;

				&:not(:first-child) {
					margin: 0;
				}
			}

		}
	}

	.hero-logos {
		@extend .hidden;

		a {
			display: inline-block;

			&:last-of-type {
				margin-left: 0.5rem;
			}
		}

		.block {
			margin-bottom: 0;
		}

		img {
			max-height: 100px;
		}

		@media #{$tabletXL} {
			display: block;
			height: auto;
			float: right;
		}

		@media #{$laptop} {
			position: relative;
    		top: -10px;

			img {
				max-height: 121px;
			}
		}

		@media #{$desktop} {
			right: 0;
		}
	}


	// Purple intro block
	.hero-text-block {
		clear: both;
		background: $color-tertiary;
		margin: 0 0 $block-margin / 2;

			@media #{$tabletXL} {
				@include fraction(2, 1.5rem);
			}

		.content {
			padding: $site-padding * 1.5;

				@media #{$tabletXL} {
					padding: $site-padding * 2.5;
				}

			h1  {

				font-weight: $font-bold;
				line-height: 1.7;

				a {
					color: $color-primary;

					&:hover {
						color: $color-black;
					}
				}
			}

			p,
			h1 {
				font-size: $h4;
				color: $color-white;
				margin-bottom: $paragraph-margin * 2;
			}

			.button {
				padding: .8rem ($site-padding * 2.5);

					@media #{$tabletXL} {
						font-size: $font-size-body + .5;
					}
			}
		}
	}

	// Need an expert?
	.need-an-expert-block {
		margin: 0;
		background: $color-white;
		width: 100%;
		position: relative;


			@media #{$tabletXL} {
				@include fraction(2, 1.5rem);
				min-height: 620px;
			}

		.title {

			min-height: 60px
		}

		.staff-cards {

			padding: .5rem .5rem 0;


			@media #{$tabletXL} {
				position: absolute;
				bottom: 0;
			}

			li {
				@include fraction((
					selector: type,
					grid: true,
					columns: 3,
					gutter: $site-padding / 3,
					bottom: $site-padding / 3,
					ie-fix : true
				));

				a {
					display: block;
					position: relative;

					&:after {
						content: '';
						@include cover(absolute);
						background: $color-secondary;
						transition: $transition;
						opacity: 0;
					}

					&:hover:after {
						content: '';
						opacity: .4;
					}

					span {
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						background: rgba($color-secondary, 0.65);
						padding: ($site-padding / 4) ($site-padding / 3);
						color: $color-white;
						font-size: $font-size-body - 0.2;
					}
				}

				img {
					@extend %image;
				}
			}
		}

		.title {
			font-size: $h1;
			text-align: center;
			padding-top: $site-padding * 2;
		}
	}
}

// Circle buttons panel
.circles-panel {
	@extend %clear-fix;
	background-color: $color-lightgrey-dark;
	margin-bottom: 0;
	padding: $block-padding;

	@media #{$laptop} {
		margin-left: -$site-padding;
		margin-right: -$site-padding;
	}

	.circles {

		li {
			font-size: 90%;

				@media #{$mobileL} {
					width: 75%;
					margin: auto;
				}

				@media #{$mobileXL} {
					width: 50%;
				}

				@media #{$tabletXL} {

					@include fraction((
						grid : true,
						columns : 3,
						selector: type,
						gutter : $block-margin,
						ie-fix: true
					));
				}

				&:not(:last-child) {
					margin-bottom: $block-margin / 2;
				}

			.title {
				font-size: $h4 - 0.1;
				display: block;
			}

			img {
				display: block;
				margin: 0 auto;
			}

			a {
				@include circle($color-white);
				background: $color-primary;
				color: $color-white;

				&:hover {
					background: $color-white;
					color: $color-primary;
				}

				span {

					&:first-child {
						@include centre(absolute);
					}

					&:nth-child(3) {
						color: $color-secondary;
					}
				}
			}
		}
	}
}

// Testimonials
// Made more global
.somek-testimonial-block {
	margin-bottom: $block-margin;
	background: url('/images/services/overview-banner.jpg') no-repeat;
	background-size: cover;

	.content {

		blockquote {
			border-left: 0;
		}
	}
}

// Pre footer
.pre-footer {
	background: $color-lightgrey;

	.call-to-action-pf {
		padding: ($site-padding * 3) 0;
	}
}

// How can we help list
.help-block {
	margin-bottom: $block-margin + 1rem;

		@media #{$laptop} {
			width: 45%;
			float: left;
			padding-top: $site-padding;
			margin: 0;
		}

	.title {
		border-bottom: 1px solid $color-midgrey;
		padding-bottom: $site-padding / 2;
		font-size: $h2;
	}

	li {
		position: relative;
		@extend %clear-fix;

			&:not(:last-child) {
				margin-bottom: $block-margin;
			}

			&:nth-child(even) {

				@media #{$tabletXL} {
					padding-left: $site-padding * 2;
				}
			}

			&:after,
			&:before {
				content: '';
				display: block;
			}

			&:before {
				float: left;
				background: url('/images/icons/pen-icon.png') no-repeat;
				width: 98px;
				height: 98px;
			}

			&:after {
				position: relative;
				top: $site-padding;
				background: lighten($color-midgrey-light, 3%);
				height: 1px;
				width: 30%;
				margin: 0 auto;
			}

		a {
			@include centreY(absolute);
			padding-left: $site-padding / 1.5;
			font-size: $font-size-body + .1;
			font-weight: $font-normal;
			color: $color-darkgrey;
			transition: all .3s ease;

				&:hover {
					color: $color-primary;
				}
								&:after,
				&:before {
					content: '';
					display: block;
					@include centreY();
				}

				&:before {
					background: $color-primary;
					width: 16px;
					height: 16px;
					border-radius: 2px;
					right: 0;
				}

				&:after {
					width: 0;
					height: 0;
					border-top: 5px solid transparent;
					border-bottom: 5px solid transparent;
					border-left: 5px solid $color-white;
					right: $block-padding - 1.2rem;
				}

				@media #{$mobileL} {
					padding-right: $block-padding ;
				}

				@media #{$tabletXL} {
					font-size: $h4;
					padding-right: $site-padding * 3;
				}
		}
	}
}

// Blog articles
.pre-footer-blog,
.pre-footer-twitter-container {

	@media #{$laptop} {
		float: left;
		width: 45%;
		margin-left: 10%;
	}

	.item {
		float: none;
		background: $color-tertiary;

		&:nth-child(n) {
			margin: 0;
			width: 100%;
		}

		&:first-child {

			margin-bottom: $block-margin / 2;
		}

	}
}


.pre-footer-blog {

	.item {

		float: none;

		&:first-child {
			// background: $color-tertiary;
			margin-bottom: $block-margin / 2;
		}
	}

}

.pre-footer-twitter-container {

	// background: $color-secondary;
}
